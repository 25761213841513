import { applyMiddleware, createStore } from "redux";
import reducer from "./reducers";
import thunk from "redux-thunk";
import LogRocket from "logrocket";

export const store = createStore(
    reducer,
    applyMiddleware(thunk, LogRocket.reduxMiddleware())
);
export type AppDispatch = typeof store.dispatch
