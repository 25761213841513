import axios from "axios";
import { EventInterface } from "../../interfaces/event.interface";
import { ProductInterface } from "../../interfaces/product.interface";
import { EventActionType } from "../../interfaces/event-action-type.enum";
import { ProductActionType } from "../../interfaces/product-action-type.enum";
import { AppState } from "../reducers";
import { StorageKeys } from "../../interfaces/storage-keys.enum";

/**
 * Lataa tapahtumat WS-API:sta
 */
export const loadEvents = () => async (dispatch: any) => {
    try {
        const result = await axios(
            `${process.env.GATSBY_WS_API_URL}/v2/${process.env.GATSBY_WS_ID}/events.json`
        );

        await result.data.events.forEach((event: EventInterface) => {
            event.products.forEach((product: ProductInterface) => {
                product.event_id = event.id;
                if (!product.availability) {
                    product.availability = {
                        available: true,
                        max: 20,
                        min: 1,
                        step: 1,
                        status: "green",
                        info: "Hyvin tilaa",
                    };
                }
            });
            dispatch({
                type: ProductActionType.ADD_PRODUCTS,
                products: event.products,
            });
        });

        dispatch({
            type: EventActionType.ADD_EVENTS,
            events: result.data.events,
        });
    } catch (e) {
        dispatch({ type: EventActionType.EVENT_LOADING_ERROR });
    }
};

/**
 * Lataa tietyn tapahtuman WS-API:sta
 * @param event_id tapahtuman id
 */
export const loadEvent = (event_id: string) => async (
    dispatch: any,
    getState: () => AppState
) => {
    const { cart } = getState();

    let url = `${process.env.GATSBY_WS_API_URL}/v2/${process.env.GATSBY_WS_ID}/events/${event_id}.json`;

    const token = localStorage.getItem(StorageKeys.EVENTIO_CART_TOKEN);

    if (cart.codes_used.length > 0) {
        url = `${process.env.GATSBY_WS_API_URL}/v2/${process.env.GATSBY_WS_ID}/events/${event_id}.json?token=${token}`;
    }

    try {
        const result = await axios.get(url);
        dispatch({
            type: EventActionType.UPDATE_EVENT,
            event: result.data.event,
        });
    } catch (error) {}
};
