import React from "react";
import {Provider} from "react-redux";
import {I18nextProvider} from "react-i18next";
import i18n from "../i18n";
import Loader from "../components/loader.component";
import {store} from "./store";
import {LiveChatLoaderProvider} from "react-live-chat-loader";

if (process.env.NODE_ENV === "development") {
    store.subscribe(() => {
        console.log("Updated", store.getState());
    });
}

declare global {
    interface Window {
        gtag: any;
        fbq: any;
    }
}

export default ({element}: any) => (
    <I18nextProvider i18n={i18n}>
        <LiveChatLoaderProvider provider="helpScout" providerKey="026b0edf-8ecf-4f09-b20c-5a969967e786">
            <Provider store={store}>
                <Loader>{element}</Loader>
            </Provider>
        </LiveChatLoaderProvider>
    </I18nextProvider>
);
