import React, { useEffect } from "react";
import { loadEvents } from "../store/actions/event.action";
import { loadProducts } from "../store/actions/product.action";
import { Helmet } from "react-helmet";
import { loadCart } from "../store/actions/cart.action";
import { useConfig } from "../hooks/use-config.hook";
import { useAppDispatch } from "../hooks/use-redux.hook";

interface LoaderProps {
}

const Loader: React.FC<LoaderProps> = (props) => {
    const { children } = props;
    const config = useConfig();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (typeof window !== `undefined`) {
            dispatch(loadEvents());
            dispatch(loadProducts());
            dispatch(loadCart());
        }
    }, []);


    return (
        <>
            <Helmet>
                <style>
                    {`   
                        html, body, #___gatsby, #gatsby-focus-wrapper {
                            min-height: 100%;
                            height: 100%;
                        }
                        
                        #gatsby-focus-wrapper > * {
                            min-height: 100%;
                        }
                `}
                </style>
                {config.metas && config.metas.description && (
                    <meta
                        name="description"
                        content={config.metas.description.fi}
                    />
                )}
            </Helmet>
            {children}
        </>
    );
};
export default Loader;
