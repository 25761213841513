import { CustomerInterface } from "interfaces/customer.interface";
import {
    IsBoolean,
    IsEmail,
    IsOptional,
    IsPhoneNumber,
    IsString,
    Matches,
    MaxLength,
    MinLength,
    ValidateIf,
} from "class-validator";

export class Customer implements CustomerInterface {
    @IsString({
        message: "Tarkista etunimesi.",
    })
    @MinLength(2, {
        message: "Tarkista etunimesi.",
    })
    @IsOptional()
    first_name?: string;

    @IsString({
        message: "Tarkista sukunimesi.",
    })
    @MinLength(1, {
        message: "Tarkista sukunimesi.",
    })
    @IsOptional()
    last_name?: string;

    @IsPhoneNumber("FI", {
        message: "Puhelinnumerosi on virheellinen.",
    })
    @IsOptional()
    phone?: string;

    @IsEmail(
        {},
        {
            message: "Sähköpostiosoitteesi on virheellinen.",
        }
    )
    @IsOptional()
    email?: string;

    @ValidateIf((o) => !!o.city || !!o.postal_code, {
        message: "Tarkista osoitteesi.",
    })
    @IsString({
        message: "Tarkista osoitteesi.",
    })
    @MinLength(2, {
        message: "Tarkista osoitteesi.",
    })
    address_line1?: string;

    @ValidateIf((o) => !!o.address_line1, {
        message: "Tarkista osoitteesi.",
    })
    @IsString({
        message: "Tarkista osoitteesi.",
    })
    @IsOptional()
    address_line2?: string;

    @ValidateIf((o) => !!o.city || !!o.address_line1, {
        message: "Postinumerosi on virheellinen.",
    })
    @IsString()
    @Matches(/^[0-9]{5}$/, {
        message: "Postinumerosi on virheellinen.",
    })
    postal_code?: string;

    @ValidateIf((o) => !!o.address_line1 || !!o.postal_code, {
        message: "Tarkista postitoimipaikkasi.",
    })
    @IsString()
    @MinLength(2, {
        message: "Tarkista postitoimipaikkasi.",
    })
    city?: string;

    @IsOptional()
    @IsString({
        message: "Tarkista asuinmaasi.",
    })
    @MinLength(2, {
        message: "Tarkista asuinmaasi.",
    })
    @MaxLength(2, {
        message: "Tarkista asuinmaasi.",
    })
    country?: string;

    @IsOptional()
    company_name?: string;

    @IsBoolean()
    marketing_permission?: boolean;
}
