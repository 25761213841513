/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "./src/css/index.css";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";

export { default as wrapRootElement } from "./src/store/ReduxWrapper";

export const onClientEntry = () => {
    if (process.env.NODE_ENV !== "development") {
        LogRocket.init("xaqq26/eventio-ws-v5");
        setupLogRocketReact(LogRocket);
    }
};
